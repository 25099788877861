import type { Locale } from '~/types/locale'

const environmentConstants = {
  'nl-nl': {
    MINIMUM_TOTAL_FOR_FREE_SHIPPING: 50,
    PHOTOSLURP_ALBUM_ID: 474,
    PHOTOSLURP_PDP_WIDGET_ID: '9DP_KZXz',
  },
  de: {
    MINIMUM_TOTAL_FOR_FREE_SHIPPING: 75,
    PHOTOSLURP_ALBUM_ID: 3039,
    PHOTOSLURP_PDP_WIDGET_ID: '9DP_L86W',
  },
  en: {
    MINIMUM_TOTAL_FOR_FREE_SHIPPING: 50,
    PHOTOSLURP_ALBUM_ID: 3680,
    PHOTOSLURP_PDP_WIDGET_ID: '9DP_MQDB',
  },
  fr: {
    MINIMUM_TOTAL_FOR_FREE_SHIPPING: 50,
    PHOTOSLURP_ALBUM_ID: 3681,
    PHOTOSLURP_PDP_WIDGET_ID: '9DP_N9DK',
  },
}

const useConstants = () => {
  const { locale } = useI18n()
  const environment = (locale.value as Locale) || 'de'
  return {
    MINIMUM_TOTAL_FOR_FREE_SHIPPING: environmentConstants[environment].MINIMUM_TOTAL_FOR_FREE_SHIPPING,
    PHOTOSLURP_ALBUM_ID: environmentConstants[environment].PHOTOSLURP_ALBUM_ID,
    PHOTOSLURP_PDP_WIDGET_ID: environmentConstants[environment].PHOTOSLURP_PDP_WIDGET_ID,
  }
}

export default useConstants
