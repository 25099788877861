<template>
  <transition name="sf-fade">
    <div class="sf-notification" :class="[{ 'display-none': !visible }, colorClass]">
      <slot name="icon" v-bind="{ icon }">
        <SfIcon class="sf-notification__icon" :class="{ 'display-none': !icon }" :icon="icon" size="lg" />
      </slot>
      <div>
        <slot name="title" v-bind="{ title }">
          <div class="sf-notification__title mobile-only" :class="{ 'display-none': !title }">
            {{ title }}
          </div>
        </slot>
        <slot name="message" v-bind="{ message }">
          <span class="sf-notification__message" :class="{ 'display-none': !message }">{{ message }}</span>
        </slot>
        <slot name="action" v-bind="{ action, actionHandler }">
          <SfButton
            class="sf-button--pure sf-notification__action"
            :class="{ 'display-none': !action }"
            @click="actionHandler"
          >
            {{ action }}
          </SfButton>
        </slot>
      </div>
      <slot name="close" v-bind="{ closeHandler }">
        <SfButton class="sf-button--pure sf-notification__close" aria-label="Close notification" @click="closeHandler">
          <SfIcon icon="cross" />
        </SfButton>
      </slot>
    </div>
  </transition>
</template>

<script setup>
const emit = defineEmits(['click:action', 'click:close'])
const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
  persistent: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: '',
  },
  message: {
    type: String,
    default: '',
  },
  action: {
    type: String,
    default: '',
  },
  type: {
    type: String,
    default: 'secondary',
    validator: function (value) {
      return ['secondary', 'info', 'success', 'warning', 'danger'].includes(value)
    },
  },
})

const icon = computed(() => {
  switch (props.type) {
    case 'success':
      return 'added_to_cart'
    case 'danger':
      return 'info_shield'
    default:
      return 'info_circle'
  }
})
const colorClass = computed(() => {
  switch (props.type) {
    case 'secondary':
      return 'color-secondary'
    case 'info':
      return 'color-info'
    case 'success':
      return 'color-success'
    case 'warning':
      return 'color-warning'
    case 'danger':
      return 'color-danger'
    default:
      return 'color-info'
  }
})

const actionHandler = () => {
  emit('click:action')
}
const closeHandler = () => {
  emit('click:close')
}
</script>

<style lang="scss">
@import './styles/molecules/SfNotification.scss';
</style>
