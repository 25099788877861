<template>
  <div
    v-if="mappedProduct"
    class="product-card"
    :class="{
      'has-colors': hasColors,
      'has-discount': mappedProduct.specialPrice,
      'is-transparent': isComingSoon || isSoldOut,
    }"
    data-testid="product-card"
    :data-sku="productSku?.replace(' ', '')"
    :data-id="productId"
    :data-source="mappedProduct.source"
  >
    <div class="product-card__image-wrapper">
      <NuxtLink
        class="sf-link sf-button sf-button--pure product-card__picture"
        :to="mappedProduct.link"
        :aria-label="'Go To Product'"
        event=""
        data-testid="product-link"
        @click.native="goToPDP()"
      >
        <img
          v-if="mappedProduct.image"
          :src="mappedProduct.image"
          :alt="mappedProduct.title"
          :width="imageWidth"
          :height="imageHeight"
          :loading="imgPriority.loading"
          :fetchpriority="imgPriority.fetchPriority"
        />
        <img
          v-if="mappedProduct.hoverImage"
          :src="mappedProduct.hoverImage"
          :alt="mappedProduct.title"
          :width="imageWidth"
          :height="imageHeight"
          :loading="imageLoadingType === 'lazy' ? 'lazy' : undefined"
          :fetchpriority="imageLoadingType === 'lazy' ? 'low' : undefined"
        />
      </NuxtLink>
      <slot name="badge">
        <ProductCardLabels
          :product="mappedProduct"
          :isComingSoon="isComingSoon"
          :isSoldOut="isSoldOut"
          :hide-plp-tags="hidePlpTags"
        />
        <ProductCardRating :rating="mappedProduct.scoreRating" :count="mappedProduct.reviewsCount" v-if="!hideRating"/>
      </slot>
      <ProductAddToWishList
        class="sf-button--pure product__add-to-wishlist product__add-to-wishlist-button product-card__wishlist-icon"
        :product="mappedProduct"
      />
      <ProductCardAddToCart v-if="showAtcButton" :product="mappedProduct"/>
    </div>

    <div v-if="isComingSoon" class="product-card__unavailable">
      <p>{{ $t('Available from date', { date: comingSoonDateFormatted }) }}</p>
      <p
        @click="
          setSoldOutFormData({
            productId,
            variantId: null,
            optionLabel: null,
            type: 'comingSoon',
            isModalVisible: true,
          })
        "
      >
        {{ $t('Notify me on release') }}
      </p>
    </div>

    <div v-else-if="isSoldOut" class="product-card__unavailable">
      <p>{{ $t('Available again soon') }}</p>
      <p
        @click="
          setSoldOutFormData({ productId, variantId: null, optionLabel: null, type: 'soldOut', isModalVisible: true })
        "
      >
        {{ $t('Notify me on release') }}
      </p>
    </div>

    <ProductCardInfo
      v-else
      :link="mappedProduct.link"
      :title="mappedProduct.title"
      :special-price="mappedProduct.specialPrice"
      :regular-price="mappedProduct.regularPrice"
      :colors="showAtcButton ? [] : mappedProduct.colors"
    />

    
  </div>
</template>

<script setup lang="ts">
import useImage from '~/composables/useImage'
import { getProductColourWheels } from '~/utils/productGetters'
import productGetters from '~/utils/getters/magentoProductGetters'
import { getLocalePathFromAbsoluteUrl } from '~/utils/urlHelpers'
import useExponeaConstants from '~/utils/constants/exponea'

const config = useRuntimeConfig()
const { checkMainProductStockEnabled } = config.public.bloomreachDiscovery
const { transformImageUrlToSize, ImageSize } = useMagentoImage()
const { routeData, pageData } = usePageStore()
const { getAttributeById } = useProductStore()
const { getMagentoImage } = useImage()
const { setSoldOutFormData } = useUiState()
const { segmentations } = storeToRefs(useExponeaStore())
const { waitUntilSegmentationsAreLoaded } = useExponeaStore()
const { appliedFilters } = storeToRefs(useBloomreachDiscoveryStore())
const router = useRouter()
const route = useRoute()
const { locale } = useI18n()
const localePath = useLocalePath()
const { $formatCurrency } = useNuxtApp()
const exponeaConstants = useExponeaConstants()

const props = defineProps({
  productImageFirst: {
    type: Boolean,
    default: false,
  },
  product: {
    type: Object,
    default: () => ({}),
  },
  imageLoadingType: {
    type: String,
    default: 'lazy',
    validator: (value: string) => {
      return ['lazy', 'eager', 'none'].includes(value)
    },
  },
  index: {
    type: Number,
    default: 0,
  },
  showAtcButton: {
    type: Boolean,
    default: false,
  },
  hideRating: {
    type: Boolean,
    default: false
  }
})

const getSpecialPrice = () => {
  if (props.product?.sale_price && props.product?.original_price !== props.product?.sale_price) {
    return $formatCurrency(props.product?.sale_price) || ''
  } else if (props.product?.price && props.product?.original_price !== props.product?.price) {
    return $formatCurrency(props.product?.price) || ''
  }
  return ''
}

const getBloomreachImage = () => {
  const currentColors = appliedFilters.value?.colors?.map((color) => color.toLowerCase())
  let variant = null

  for (let colorId in currentColors) {
    variant = props.product?.variants?.find((variant) => variant.sku_color === currentColors?.[colorId])
    if (variant?.filter_image?.[0]) return variant.filter_image[0]
  }

  return props.productImageFirst
    ? props.product?.thumbnail || props.product?.thumb_image
    : props.product?.thumb_image || ''
}

const hidePlpTags = ref(pageData?.hidePlpTags)

const checkIfLabelsAreAllowed = (labelOrlabels) => {
  if (!labelOrlabels) return
  return Array.isArray(labelOrlabels)
    ? labelOrlabels?.filter(
        (label) =>
          !hidePlpTags.value
            ?.split(',')
            .map((hideTag) => hideTag.toLowerCase())
            .includes(label?.toLowerCase()),
      )
    : !hidePlpTags.value
          ?.split(',')
          .map((hideTag) => hideTag.toLowerCase())
          .includes(labelOrlabels?.toLowerCase())
      ? [labelOrlabels]
      : []
}

// Check to see if the Exponea user has a preference for gold or silver
// If they do, we will use this preference to display the correct image
// Value in Exponea is Dutch, so we translate it
const getMagentoImageWithUserPreference = async () => {
  await waitUntilSegmentationsAreLoaded()
  const preference = segmentations.value.get(exponeaConstants['SEGMENTATION_JEWELLERY_PREFERENCE_ID']) || 'Gold'
  const mapPreference = {
    Gold: ['Goud', 'Gold', 'Silver', 'Doré'],
    Silver: ['Zilver', 'Silber', 'Silver', 'Argenté'],
  }
  const array = mapPreference[preference] ?? []
  const variant = props.product?.variants?.find((variant) =>
    variant.attributes?.find((attr) => array.includes(attr.label)),
  )
  if (!variant?.product?.thumbnail?.url) return
  const image = transformImageUrlToSize(getMagentoImage(variant?.product?.thumbnail?.url), ImageSize.Large) || ''
  mappedProduct.value.image = image
  mappedProduct.value.hoverImage = image
}

if (['ConfigurableProduct', 'SimpleProduct'].includes(props.product?.__typename)) {
  getMagentoImageWithUserPreference()
}

const getPriceOrHardcodedGiftCardRange = (sku, price) => {
  let resultPrice = ''
  if (sku === 'mjgiftcard') {
    // hardcoded for mjgiftcard only
    resultPrice = $formatCurrency(10) + ' - ' + $formatCurrency(150)
  } else {
    resultPrice = $formatCurrency(price) || ''
  }
  return resultPrice
}

const mapMagentoProduct = () => {
  const regularPrice = getPriceOrHardcodedGiftCardRange(
    props.product?.sku,
    productGetters.getPrice(props.product).regular,
  )

  return {
    pid: props.product?.pid || '',
    title: props.product?.name || '',
    image: transformImageUrlToSize(getMagentoImage(props.product.thumbnail?.url), ImageSize.Large) || '',
    hoverImage: transformImageUrlToSize(getMagentoImage(props.product.thumbnail_hover), ImageSize.Large) || '',
    regularPrice: regularPrice,
    specialPrice:
      (productGetters.getPrice(props.product).special &&
        $formatCurrency(productGetters.getPrice(props.product).special)?.toString()) ||
      '',
    link: localePath(`${productGetters.getSlug(props.product)}`),
    colors: getProductColourWheels(props.product),
    scoreRating: (props.product.rating_summary / 100) * 5 || 0,
    reviewsCount: props.product.review_count || 0,
    sku: props.product?.sku,
    date_online: props.product?.date_online,
    labels: checkIfLabelsAreAllowed(getAttributeById(props.product?.product_label, 'product_label')),
    variants: props.product?.variants?.map((variant) => {
      return {
        ...variant,
        out_of_stock: [[variant.stock_status === 'OUT_OF_STOCK'].toString()],
      }
    }),
    out_of_stock: props.product?.stock_status === 'OUT_OF_STOCK',
    source: 'Magento',
  }
}

const mapExponeaProduct = () => {
  const regularPrice = getPriceOrHardcodedGiftCardRange(props.product?.sku, props.product?.price?.toString())
  return {
    pid: props.product?.item_id || '',
    title: props.product?.title || '',
    image: transformImageUrlToSize(props.product?.image, ImageSize.Large, 'bloomreach') || '',
    hoverImage: transformImageUrlToSize(props.product?.hover_image_url, ImageSize.Large, 'bloomreach') || '',
    regularPrice: regularPrice,
    specialPrice: props.product?.sale_price ? $formatCurrency(props.product?.sale_price?.toString()) : null,
    link: props.product?.url ? getLocalePathFromAbsoluteUrl(props.product?.url) : '',
    colors: [],
    scoreRating: props.product?.reviews_rating_summary || 0,
    reviewsCount: props.product?.review_count || 0,
    sku: props.product?.sku,
    date_online: props.product?.date_online,
    variants: props.product?.variants,
    source: 'Exponea',
  }
}

const mapBloomreachProduct = () => {
  const bloomReachColors = () => {
    let colors = []
    if (
      props.product?.variants &&
      props.product?.variants.length > 0 &&
      props.product?.variants[0]?.active_colors_hex?.length
    ) {
      colors = props.product?.variants[0]?.active_colors_hex
    }
    if (props.product?.style_swatches_hex?.length) {
      colors = props.product?.style_swatches_hex
    }
    return colors?.map((color) => {
      return { image: color }
    })
  }

  const regularPrice = getPriceOrHardcodedGiftCardRange(props.product?.sku, props.product?.original_price?.toString())

  return {
    pid: props.product?.pid || '',
    title: props.product?.title || '',
    image: getBloomreachImage(),
    hoverImage: props.product?.hover_image_url || '',
    regularPrice: regularPrice,
    specialPrice: getSpecialPrice(),
    link: props.product?.url ? getLocalePathFromAbsoluteUrl(props.product?.url) : '',
    colors: bloomReachColors(),
    scoreRating: props.product?.reviews_rating_summary,
    reviewsCount: props.product?.review_count,
    sku: props.product?.sku,
    date_online: props.product?.date_online,
    labels: checkIfLabelsAreAllowed(props.product?.product_label),
    out_of_stock: props.product?.out_of_stock === 'true',
    variants: props.product?.variants,
    source: 'Bloomreach',
  }
}

const mapProduct = () => {
  // Magento
  if (['ConfigurableProduct', 'SimpleProduct'].includes(props.product?.__typename)) return mapMagentoProduct()

  // Exponea
  if (props.product?.__typename === 'ExponeaProduct') return mapExponeaProduct()

  // Bloomreach
  return mapBloomreachProduct()
}

const mappedProduct = ref(mapProduct())

const imageWidth = 300
const imageHeight = 450

const hasColors = () => Boolean(mappedProduct.value?.colors?.length)

const isSoldOut = (() => {
  if (checkMainProductStockEnabled && mappedProduct.value?.out_of_stock) {
    return true
  } else {
    return (
      mappedProduct.value?.variants?.length > 0 &&
      mappedProduct.value?.variants?.every((variant) => variant.out_of_stock?.[0] !== 'false')
    )
  }
})()

const isValidDate = (dateString: string) => {
  const date = new Date(dateString)
  return !isNaN(date.getTime())
}

const comingSoonDateFormatted =
  mappedProduct.value?.date_online && isValidDate(mappedProduct.value?.date_online)
    ? new Intl.DateTimeFormat(locale.value).format(new Date(mappedProduct.value?.date_online))
    : null

const isComingSoon: boolean = mappedProduct.value?.date_online
  ? isValidDate(mappedProduct.value?.date_online) && new Date(mappedProduct.value?.date_online) > new Date()
  : false

const productId = mappedProduct.value?.pid
const productSku = mappedProduct.value?.sku
const mapFetchPriority = {
  eager: 'high',
  lazy: 'low',
}
const imgPriority = ref({
  fetchPriority: mapFetchPriority[props.imageLoadingType],
  loading: props.imageLoadingType === 'none' ? undefined : props.imageLoadingType,
})

const setScrollPosition = (offsetTop: number) => {
  if (['CATEGORY', 'SEARCH'].includes(routeData.type)) {
    const scrollPositions = JSON.parse(sessionStorage.getItem('scrollPositions'))
    sessionStorage.setItem(
      'scrollPositions',
      JSON.stringify({
        ...scrollPositions,
        [route.fullPath]: {
          sku: props.product.sku,
          offsetTop,
        },
      }),
    )
  }
}

const goToPDP = async () => {
  const offsetTop = document
    .querySelector(`[data-sku=${props.product.sku?.replace(' ', '')}]`)
    ?.getBoundingClientRect().top
  const currentPage = parseInt((route.query.p as string) || '1')

  // Save the right page to scroll to on browser back
  if (props.product?.page !== currentPage && props.product?.page > 1) {
    await router.push({ path: route.path, query: { ...route.query, p: props.product?.page } })
  } else if (props.product?.page !== currentPage && props.product?.page === 1) {
    let { p, ...query } = route.query
    await router.push({ path: route.path, query })
  }

  setScrollPosition(offsetTop)

  router.push({ path: mappedProduct.value.link })
}
</script>
<style lang="scss">
.product-card {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--spacer-xs);
  max-width: var(--mj-product-card-max-width, 20rem);

  &:hover {
    img:nth-child(2) {
      opacity: 1;
    }
  }

  .sf-link {
    --button-height: auto;
  }

  &__wishlist-icon {
    position: absolute;
    top: var(--spacer-xs);
    right: var(--spacer-xs);
    color: var(--gray-secondary-color);
    background: var(--white-color);
    border-radius: 50%;
    height: 42px;
    width: 42px;

    svg {
      margin: 0 0 -2px;
      width: 24px;
      height: 24px;
    }

    .sf-loader {
      border-radius: 50%;
      overflow: hidden;
    }
  }

  &__image-wrapper {
    position: relative;
  }

  &__picture {
    position: relative;
    overflow: hidden;
    border-radius: 4px 4px 0 0;
    width: 100%;
    aspect-ratio: var(--product-image-ratio);
    background-color: var(--sand-background-color);

    img:nth-child(2) {
      opacity: 0;
    }

    img {
      position: absolute;
      max-height: 100%;
      max-width: 100%;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      object-fit: cover;
    }
  }

  &__unavailable {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: var(--spacer-xs) 0 0;

    p {
      margin: 0;
      font-size: 12px;

      &:first-child {
        font-weight: 600;
      }

      &:last-child {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .sf-image[alt=''] {
    outline: none;
  }

  .sf-image--placeholder {
    display: none;
    font-size: 0;
    aspect-ratio: var(--product-image-ratio);
  }

  .sf-image--overlay {
    .sf-image--wrapper {
      position: static;
    }

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
    }
  }

  &.has-discount {
    .sf-price__special {
      color: var(--red-color);
    }
  }

  &.is-transparent {
    opacity: 0.7;
  }
}
</style>
