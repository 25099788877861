<template>
  <div
    v-if="rating"
    ref="ab24040rating"
    class="product-card-rating"
    :class="{ 'ab24040-v1': ab24040 === 'v1' }"
    data-testid="product-card-rating"
  >
    <template v-if="ab24040 === 'v1'">
      <span class="sf-icon sf-rating__icon" style="--icon-size: 0.875rem">
        <svg class="sf-icon-path" viewBox="0 0 24 24" preserveAspectRatio="none">
          <defs class="">
            <linearGradient id="linearGradient-[object Object]" x1="0" y1="0" x2="1" y2="0">
              <stop offset="1" stop-color="var(--secondary-color)"></stop>
              <stop offset="0" stop-color="var(--icon-color-negative, var(--c-gray-variant))"></stop>
            </linearGradient>
          </defs>
          <path
            d="M11.963,0l2.732,8.407h8.84l-7.152,5.2,2.732,8.407-7.152-5.2-7.152,5.2L7.543,13.6.392,8.407h8.84Z"
            fill="var(--secondary-color)"
            style="height: 100%"
          ></path>
        </svg>
      </span>
      <span class="product__average-rating" data-testid="product-card-rating__average-rating">
        {{ Number(rating).toFixed(1) }}
      </span>

      <span class="product-card-rating__count" data-testid="product-card-rating__count"> ({{ count }}) </span>
    </template>

    <template v-else>
      <SfRating
        class="product__average-rating"
        :score="rating"
        :max="5"
        :icon="starIconPath"
        data-testid="product-card-rating__average-rating"
      />

      <span class="product-card-rating__count" data-testid="product-card-rating__count">
        {{ count }} {{ $t(count === 1 ? 'Review' : 'Reviews') }}
      </span>
    </template>
  </div>
</template>

<script setup lang="ts">
const abTestStore = useAbTestStore()
const { tests } = storeToRefs(abTestStore)
const { sendAbTestEvent } = abTestStore
const ab24040 = ref()
const ab24040rating = ref()

defineProps({
  rating: {
    type: Number,
    default: 0,
  },
  count: {
    type: Number,
    default: 0,
  },
})

const starIconPath = 'M11.963,0l2.732,8.407h8.84l-7.152,5.2,2.732,8.407-7.152-5.2-7.152,5.2L7.543,13.6.392,8.407h8.84Z'

onMounted(() => {
  ab24040.value = tests?.value?.get(24040)
  if (ab24040rating.value) {
    let observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (ab24040.value === 'v1' || ab24040.value === 'v0') {
              sendAbTestEvent(24040, 'show')
            }
            observer.unobserve(entry.target)
          }
        })
      },
      {
        rootMargin: '0px',
        threshold: 0.1,
      },
    )
    observer.observe(ab24040rating.value)
  }
})
</script>

<style lang="scss">
.product-card-rating {
  pointer-events: none;
  position: absolute;
  bottom: 8px;
  left: 50%;
  transform: translateX(-50%);

  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px 8px;
  border-radius: 4px;
  background: var(--c-white);
  font-size: 8px;

  @include for-desktop {
    bottom: 12px;
    gap: 8px;
    font-size: 12px;
  }

  @include for-mobile {
    .sf-rating__icon {
      --icon-size: 10px !important;
    }
  }

  &.ab24040-v1 {
    padding: 7px 4px;
    font-size: 10px;
    line-height: 1.2;
    gap: 4px;
    left: 8px;
    transform: translateX(0);

    @include for-desktop {
      padding: 9px 8px;
      font-size: 13px;
      gap: 6px;
    }

    .product__average-rating {
      font-weight: 600;
    }
  }

  &__count {
    width: max-content;
  }
}
</style>
